import { PageProps, navigate } from 'gatsby';
import { useEffect } from 'react';

const Event: React.FC<PageProps> = () => {
  useEffect(() => {
    navigate('/');
  }, []);
  return null;
};

export default Event;
